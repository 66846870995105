<template>
  <CCol
    sm="12"
    md="6"
    lg="4"
    class="widget"
  >
    <div>
      <label>{{ label }}</label>
      <CIcon :name="icon" height="30" />
      <p class="capitalize">
        {{ parsedValue }}
      </p>
    </div>
  </CCol>
</template>

<script>
export default {
  name: 'PostingDetailsWidget',
  props: {
    value: {
      type: [String, Boolean],
      default: null
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    parsedValue () {
      if (this.label === 'Smoking Policy') {
        if (this.value === true) {
          return 'No Smoking'
        } else if (this.value === false) {
          return 'Smoking Ok'
        }
      }
      if (this.value === null) {
        return 'Unknown'
      } else if (this.value === true) {
        return 'Yes'
      } else if (this.value === false) {
        return 'No'
      }
      return this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 10px;

  div {
    height: 100%;
    padding: 1em;
    border: 1px solid $secondary-200;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      fill: $info;
    }

    p {
      margin: 0.5em 0 0;

      &.capitalize {
        text-transform: capitalize;
      }
    }
  }
}
</style>
