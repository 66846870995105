<template>
  <div class="posting-detail-field">
    <label>{{ label }}</label>
    <template v-if="field.length">
      <p v-for="fieldItem in field" :key="fieldItem">
        {{ fieldItem }}
      </p>
    </template>
    <PostingDetailSupport v-else />
  </div>
</template>

<script>
import PostingDetailSupport from './PostingDetailSupport.vue'

export default {
  name: 'PostingDetailMultiField',
  components: {
    PostingDetailSupport
  },
  props: {
    field: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>
