<template>
  <CAlert color="info">
    This field is pulled dynamically from your website or our system. Questions?
    <CLink @click="openSupportModal">
      Contact us
    </CLink> anytime!
  </CAlert>
</template>

<script>
export default {
  methods: {
    openSupportModal () {
      this.$store.dispatch('support/open')
    }
  }
}
</script>
