<template>
  <div class="list-input">
    <div class="subheader">
      <label>{{ label }}</label>
      <CLink
        @click="addItem()"
      >
        + Add item
      </CLink>
    </div>
    <div v-for="(item, index) in field" :key="index">
      <CTextarea
        v-model="field[index]"
        :is-valid="validateField('field', index)"
        :invalid-feedback="invalidFeedback"
        :rows="rows"
        @update:value="setValue('field', index)"
      />
    </div>
  </div>
</template>

<script>
/**
 * RListInput
 *
 * Custom multiple text input component for array fields
 *
 *
 * Example
 *
 * <RListInput
 *   ref="amenitiesProperty"
 *   :items="formData.amenities_property"
 *   label="Amenities (property)"
 *   invalid-feedback="This field cannot exceed 1000 characters"
 *   :rows="2"
 *   :max-length="1000"
 * />
 *
 *
 */
import { validationMixin } from 'vuelidate'
import { maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'RListInput',
  mixins: [validationMixin],
  validations () {
    return {
      field: { $each: { maxLength: maxLength(this.maxLength) } }
    }
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: ''
    },
    invalidFeedback: {
      type: String,
      default: 'This field is invalid'
    },
    rows: {
      type: Number,
      default: 1
    },
    maxLength: {
      type: Number,
      default: 1000
    }
  },
  data () {
    return {
      field: [],
      loading: false
    }
  },
  computed: {
    isValid () {
      return !this.$v.$invalid
    }
  },
  watch: {
    items: {
      handler (items) {
        if (!items) {
          return
        }
        this.field = items
      },
      immediate: true
    }
  },
  created () {
    // Render at least one item
    if (!this.items.length) {
      this.addItem()
    }
  },
  updated () {
    if (!this.items.length) {
      this.addItem()
    }
  },
  methods: {
    /**
     * Set a form field, and mark the field as dirty.
     *
     * @param {String} fieldName
     */
    setValue (fieldName, index) {
      this.$v[fieldName].$each[index].$touch()
    },
    /**
     * Validate a form field.
     *
     * @param {String} fieldName
     * @returns {Boolean}
     */
    validateField (fieldName, index) {
      const field = this.$v[fieldName].$each[index]
      if (!field.$dirty) {
        return null
      }
      return !field.$invalid
    },
    /**
     * Validate the form, triggering bootstrap validation.
     *
     * Called by parent using refs.
     *
     * @returns {Boolean}
     */
    validate () {
      this.$v.$touch()
      return this.isValid
    },
    /**
     * Reset the validation, removing bootstrap validation.
     *
     * Called by parent using refs.
     */
    reset () {
      this.$v.$reset()
    },
    addItem () {
      this.$v.$reset()
      this.items.splice(0, 0, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.subheader {
  display: flex;
  justify-content: space-between;
}
</style>
