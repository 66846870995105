<template>
  <CCol>
    <CRow class="mx-n4">
      <PostingDetailsWidget
        label="Furnished"
        icon="md-bed"
        :value="property.furnished"
      />
      <PostingDetailsWidget
        label="Dog Policy"
        icon="md-dog"
        :value="property.dog_policy"
      />
      <PostingDetailsWidget
        label="Cat Policy"
        icon="md-pets"
        :value="property.cat_policy"
      />
      <PostingDetailsWidget
        label="Parking"
        icon="md-car"
        :value="property.parking"
      />
      <PostingDetailsWidget
        label="EV Charging"
        icon="md-ev-station"
        :value="property.ev_charging"
      />
      <PostingDetailsWidget
        label="Air Conditioning"
        icon="md-air-condition"
        :value="property.air_conditioning"
      />
      <PostingDetailsWidget
        label="Laundry"
        icon="md-laundry"
        :value="property.laundry"
      />
      <PostingDetailsWidget
        label="Smoking Policy"
        icon="md-smoking"
        :value="property.smoking_policy"
      />
      <PostingDetailsWidget
        label="Wheelchair Accessible"
        icon="md-wheelchair"
        :value="property.wheelchair_access"
      />
    </CRow>
  </CCol>
</template>

<script>
import PostingDetailsWidget from './PostingDetailsWidget'

export default {
  name: 'PostingDetailAmenities',
  components: {
    PostingDetailsWidget
  },
  props: {
    property: {
      default: null,
      type: Object
    }
  }
}
</script>
